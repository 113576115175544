// *********************************************************
// Section portfolio
// *********************************************************

.project {
    
    &__image {

        &--imhd {
            position: relative;

            @media only screen and (max-width: $extra-small-device) {
                padding-top: 3rem;
            }
        }
 
        &--silentel {
            position: relative;
            padding-right: 3rem;
            bottom: -3rem;

            @media only screen and (max-width: $extra-small-device) {
                padding: 3rem;
                padding-right: 0;
                max-width: none;
                width: 100%;
            }
        }

        &--zaparkujto {
            position: relative;
            right: -12rem;

            @media only screen and (max-width: $large-device) {
                right: -2.5rem;
            }

            @media only screen and (max-width: $extra-small-device) {
                padding-top: 3rem;
                right: 1.5;
                max-width: none;
                width: 100%;
            }
        }

        &--vodka {
            position: relative;
            max-width: none;
            height: 80vh;

            @media only screen and (max-width: $extra-small-device) {
                padding: 3rem;
                max-width: 100%;
                height: 30rem;
                margin: auto;
            }
        }

        &--bethereum {

            @media only screen and (max-width: $extra-small-device) {
                padding-bottom: 3rem;
                max-width: none;
                width: 100%;
            }
        }

        &--hellenski {
            z-index: 1;

            @media only screen and (max-width: $extra-small-device) {
                position: relative;
                bottom: -3rem;
                padding-top: 3rem;
            }
        }

        &--fuergy {

            @media only screen and (max-width: $extra-small-device) {
                padding-bottom: 3rem;
                max-width: none;
                width: 100%;
            }
        }

        &--promobay {
            position: relative;
            right: -12rem;

            @media only screen and (max-width: $large-device) {
                right: -2rem;
            }

            @media only screen and (max-width: $extra-small-device) {
                padding-top: 3rem;
                max-width: none;
                width: 100%;
            }
        }

        &--today-bank {
            position: relative;
            left: -13rem;

            @media only screen and (max-width: $large-device) {
                left: -2rem;
            }

            @media only screen and (max-width: $extra-small-device) {
                padding-bottom: 3rem;
                max-width: none;
                width: 100%;
            }
        }

        &--emm {

            @media only screen and (max-width: $extra-small-device) {
                padding-top: 3rem;
                max-width: none;
                width: 100%;
            }
        }

        &--medialer {
            position: relative;
            left: -12.5rem;

            @media only screen and (max-width: $large-device) {
                left: -2rem;
            }

            @media only screen and (max-width: $extra-small-device) {
                padding-bottom: 3rem;
                max-width: none;
                width: 100%;
            }
        }

        &--topcoach {

            @media only screen and (max-width: $extra-small-device) {
                padding-top: 3rem;
                max-width: none;
                width: 100%;
            }
        }

        &--correct-green {

            @media only screen and (max-width: $extra-small-device) {
                padding-bottom: 3rem;
                max-width: none;
                width: 100%;
            }
        }

        &--nocu {

            @media only screen and (max-width: $extra-small-device) {
                padding-top: 3rem;
                margin-left: 0;
                max-width: none;
                width: 100%;
            }
        }
    }

    &__logo {
        max-width: 7.5rem;
        margin-bottom: 2rem; 

        &--hero {
            max-width: 60%;
            margin: auto;
        }

        @media only screen and (min-width: $extra-large-device) {
            max-width: none;
            width: 10vw;
        }
    }

    &__heading {
        position: relative;
        color: #000000;
        font-size: 5rem;
        font-size: 4vw;
        letter-spacing: -0.04em;
        line-height: 1.2;
        font-weight: bold;
        margin-bottom: 4rem;
        z-index: 99;

        &--hero {
            line-height: 1;
            font-size: 1.25rem;
            text-align: center;
            margin-top: 2.75rem;

            @media only screen and (max-width: $small-device) {
                margin-top: 0; 
            }
        }

        &--dark {
            color: #FFFFFF;
        }

        &--vodka {
            color: color($portfolio, vodka, heading);
        }

        @media only screen and (max-width: $extra-small-device) {
            font-size: 2.4rem;     
        }
    }

    &__mark {

        &--hellenski {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            z-index: 9;
            max-width: 100%;
        }
    }
}