// *********************************************************
// Layout
// *********************************************************

.section {
    display: flex;
    align-items: center;
    min-height: 100vh;
    overflow: hidden;

    @media only screen and (max-width: $large-device) {
        min-height: auto;
        height: auto;
    }

    &__hero {
        height: 100vh;
        @media only screen and (max-width: $medium-device), (max-height: em(550)) {
            background-size: 80% 80%;
        }
    }

    &--services-hero {
        min-height: calc(100vh - 120px);

        @media only screen and (max-width: $medium-device) {
            min-height: rem(500);
        }
    }

    &--services {
        padding: rem(100) 0;
        min-height: auto;

        @media only screen and (max-width: $small-device) {
            padding: rem(60) 0;
        }
    }

    &--services:nth-child(even) [class~="grid"],
    &--services:nth-child(even) [class*="grid-"],
    &--services:nth-child(even) [class*="grid_"] {

        @media only screen and (max-width: $small-device) {
            flex-direction: column-reverse;
        }
    }

    &--services-dark {
        background: #000000;
    }

    &__content {
        margin: auto;
        height: 100%;
        width: 100%;
        position: relative;


        [class~="grid"], [class*="grid-"], [class*="grid_"] {
            padding: 0 rem(200);

            @media only screen and (max-width: $large-device) {
                padding: 0 2rem;
            }

            @media only screen and (max-width: $extra-small-device) {
                padding: 6rem 2rem;
            }
        }

        .col-images {
            padding: 6rem 0;

            @media only screen and (max-width: $extra-small-device) {
                padding: 0;
                text-align: center;
            }
        }
    }

    &__content--services {
        margin: auto;
        height: 100%;
        width: 100%;
        position: relative;


        [class~="grid"], [class*="grid-"], [class*="grid_"] {
            padding: 0 rem(200);

            @media only screen and (max-width: $large-device) {
                padding: 0 2rem;
            }

            @media only screen and (max-width: $extra-small-device) {
                padding: 0 2rem;
            }
        }
    }

    // background for section

    &--imhd {
        background: color($portfolio, imhd, bg);
    }

    &--silentel {
        background: color($portfolio, silentel, bg);
        background-size: cover;
    }

    &--vodka {
        background: color($portfolio, vodka, bg);
    }

    &--zaparkujto {
        background: color($portfolio, zaparkujto, bg);
        background-size: cover;
    }

    &--bethereum {
        background: color($portfolio, bethereum, bg);
        background-size: cover;
    }

    &--hellenski {
        background: color($portfolio, hellenski, bg);
    }

    &--fuergy {
        background: color($portfolio, fuergy, bg);
        background-size: cover;
    }

    &--promobay {
        background: color($portfolio, promobay, bg);
    }

    &--today-bank {
        background: color($portfolio, today-bank, bg);
    }

    &--emm {
        background: color($portfolio, emm, bg);
        background-size: cover;
    }

    &--medialer {
        background: color($portfolio, medialer, bg);
    }

    &--topcoach {
        background: color($portfolio, topcoach, bg);
        background-size: cover;
    }

    
    &--correct-green {
        background: color($portfolio, correct-green, bg);
    }

    &--nocu {
        background: color($portfolio, nocu, bg);
        background-size: cover;
    }

    &--testimonial {
        background: #FFFFFF;
        height: auto !important;
        min-height: auto;
        padding: 5rem 0 7rem 0;
    }

}
