// *********************************************************
// Chat button
// *********************************************************

div.chat-button {
    position: fixed;
    top: auto !important;
    left: auto  !important;
    bottom: 2rem !important;
    // left: rem(73) !important;
    right: rem(71) !important;
    height: 3rem !important;
    width: 3rem !important;
    border-radius: 50%;
    z-index: 9999999;
    overflow: hidden;
    transition: all 0.3s ease-in-out;

    @media only screen and (max-width: $large-device) {
        right: rem(21) !important;
    }

    @media only screen and (max-width: $small-device) {
        right: rem(32) !important;
    }
}

.chat-button {

    &__link {
        background: color($chat-button, default, background);
        display: block;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

              
    }

    &__icon-wrapper {
        width: rem(20);

    }

    &__icon {
        fill: currentColor;
        color: color($chat-button, default, color);
    }

    &--hide {
        display: none
    }
}

.chat-htInner {
    overflow: hidden !important;
}

// Style for section

.chat-htHeader {
  
    &.default {
        position: relative;

    }
    
    &.black {

        .chat-button__link {
            background: color($chat-button, black, color);
        }
     
        .chat-button__icon {
            color: color($chat-button, white, color);
    
        }
    }

    &.white {
        box-shadow: none;
        .chat-button__link {
            background: color($chat-button, white, color);
        }
    
        .chat-button__icon {
            color: color($chat-button, black, color);
        }
    }

    &.vodka {

        .chat-button__link {
            background: color($chat-button, vodka, background);
        }
      
        .chat-button__icon {
            color: color($chat-button, vodka, color);
        }
    }
}
