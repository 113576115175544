// *********************************************************
// Language version
// *********************************************************

.language {
    position: fixed;
    left: rem(50);
    bottom: 2rem;
    transition: all 0.3s ease-in-out;
    z-index: 9999999;

    // Button link style
    
    &__link {
        font-weight: bold;
        position: relative;
        font-size: rem(15);
        color: color($logo, default, text);
        text-transform: uppercase;

        &::before {
            content: "";
            display: inline-block;
            height: 100%;
            width: 100%;
            border-radius: 3rem;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            transition: all .4s;
        }

        // Button link hover style for no-phone and no-touch disply

        @media only screen and (min-width: $small-device), (any-hover: hover) {
        
            &:hover {
                color: $primary-color;
            }

            &:hover::before {
                transform: scaleX(2) scaleY(2);
                background: $primary-color;
                opacity: 0;
            }
    
            &:focus {
                outline: 1px solid $primary-color;
                transition: all 0.2s linear; }
            
        }
    }

    &--hide {
        left: -100%;
    }

    // Style if open navigation

    &--nav {
        // Show lang button
        left: 2.6rem !important;
        z-index: 999999999;
        
        // Style button link for open nav

        .language__link {
            color: color($chat-button, open-nav, color);
        }

        // Hover style for no-phone and no-touch disply 

        @media only screen and (max-width: $small-device), (any-hover: hover) {

            .language__link:hover {
                color: $primary-color;
            }
        }
    }

    &--white{
        left: 5.3rem !important;

        @media only screen and (max-width: $small-device) {
            left: 1.8rem !important;
        }
    }

    &--white &__link {
        color: #FFFFFF;
        visibility: hidden;
    }

    &--visible,
    &--visible &__link {
        visibility: visible;
    }
}
