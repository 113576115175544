// *********************************************************
// Clients
// *********************************************************

.clients {

    flex-direction: row !important;

    &__heading {
        font-size: rem(60);
        font-size: 4vw;
        font-weight: bold;
        color: #FFFFFF;
        margin-bottom: 8rem;
        margin-bottom: 8vw;
        text-align: center;

        @media only screen and (max-width: $medium-device) {
            font-size: rem(48);
            margin-bottom: rem(84);
        }

        @media only screen and (max-width: $small-device) {
            font-size: rem(32);
            // margin-bottom: rem(64);
        }

        @media only screen and (max-width: $extra-small-device) {
            font-size: rem(24);
            // margin-bottom: rem(48);
        }
    }

    &__list {
        width: 80%;
        font-size: rem(14);
        font-size: 1vw;
        margin: 0 auto;

        @media only screen and (max-width: $medium-device) {
            font-size: rem(14);
        }
    }

    &__item {
       
    }

    &__item:not(:last-child) {
        margin-bottom: 1rem;
        margin-bottom: 1vw;

        @media only screen and (max-width: $medium-device) {
            margin-bottom: 1rem;
        }
    }

    &__link,
    &__link:link,
    &__link:visited {
        display: block;
        position: relative;
        color: #DBEAFF;
        padding-bottom: 1rem;
        padding-bottom: 1vw;
        border-bottom: 1px solid #303238;

        @media only screen and (max-width: $medium-device) {
            padding-bottom: 1rem;
        }

        @media only screen and (min-width: $small-device), (any-hover: hover) {

            &:hover {
                color: #FFFFFF;
            }

            &:hover::before {
                width: 100%;
            }
        }

        // Style for all browser that support background-clip: text
        @supports (background-clip: text) or (-webkit-background-clip: text) {
            color: transparent;
            -webkit-background-clip: text;
            background-clip: text;
            background-image: linear-gradient(to right, #DBEAFF 50%,$primary-color 50%);
            background-size: 200%;
            background-position: 0% 0;
            transition: background-position 0.5s ease-in-out;

            // Hover style for all browser that support background-clip: text
            @media only screen and (min-width: $small-device), (any-hover: hover) {

                &:hover {
                    color: transparent;
                    background-position: -100% 0;
                    text-decoration: none;
                }
            }
        }

        &::before {
            content: " ";
            display: block;
            position: absolute;
            right: 0;
            bottom: -0.5px;
            background: $primary-color;
            height: rem(1);
            width: 0;
            z-index: 9;
            box-shadow: 0 0 16px #006EFF;
            transition: width 0.3s ease-in-out;
        }
    }
}
