// *********************************************************
// Scroll bar for chrome, opera and safary
// *********************************************************

::-webkit-scrollbar {
    width: 7px;
}
   
::-webkit-scrollbar-thumb {
    background-color: color($scroll-bar, thumb, color);
    border-radius: 20px;
}
  
::-webkit-scrollbar-track {
    background-color: color($scroll-bar, scroll, color);
    border-radius: 20px;
}

.scroll--services {
    ::-webkit-scrollbar-track {
        background-color: #181818;
    }
}
