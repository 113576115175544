// *********************************************************
// Social fixed
// *********************************************************
// Scroll social style

div.social {
    position: fixed;
    top: 50% !important;
    transform: translateY(-50%);
    margin: auto;
    width: 1rem;
    right: rem(89) !important;
    height: auto !important;
    left: auto !important;
    bottom: auto !important;
    min-height: 490px !important;
    color: $dark-color;
    z-index: 99999999;

    @media only screen and (max-width: $large-device) {
        right: rem(38) !important;
    }

    @media only screen and (max-width: $medium-device), (max-height: em(700)) {
        display: none;
    }
}

.social {

    &__text {
        font-size: 13px;
        font-weight: 700;
        letter-spacing: 0.2em;
        color: inherit;
        fill: currentColor;
        opacity: 0.3;
    }

    &__link:hover &__text {
        @media only screen and (min-width: $small-device), (any-hover: hover) {
            opacity: 1;
        }
    }

    &--hide {
        display: none;
    }
}

.social-htInner {
    overflow: hidden !important;
   
}

// Style for section

.social-htHeader {

    &.white {
        color: $light-color;
    }

    &.black {
        color: $dark-color;
    }
}

// Navigation social style


.nav-social {

    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    margin: auto;
    width: 1rem;
    right: rem(89);
    min-height: 490px;
    color: color($social, black, color);
    z-index: 999999;

    @media only screen and (max-width: $small-device), (any-hover: hover) {

        &__link:hover {
            color: $primary-color;
            position: relative;
            z-index: 99999;
        }
    }

    &__text {
        font-size: 13px;
        font-weight: 700;
        letter-spacing: 0.2em;
        color: inherit;
        fill: currentColor;
    }

    @media only screen and (max-width: $small-device) {
        display: none;
    }

    @media only screen and (max-height: em(700)) {
        display: none;
    }
}

