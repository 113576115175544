// *********************************************************
// Header
// *********************************************************

.header {
    position: absolute;
    z-index: 999999;

    &--nav {
        visibility: visible;
        position: fixed;
        background: transparent !important;
    }
}

.header--services {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0 rem(72);
    height: rem(120);
    z-index: 9999999;
    // visibility: hidden;
    width: 100%;
    pointer-events: none;

    @media only screen and (max-width: $medium-device) {
        padding: 0 rem(15);
    }
}

.header--services-active {
    
    @media only screen and (min-width: $medium-device) {
        background: #181818;
        visibility: visible;
        transition: all 0.3s linear;
        
    }
}
