// *********************************************************
// Hamburger icon + text menu
// *********************************************************

// Blik style

.hamburger__hover {
    position: fixed;
    width: 3rem;
    height: 3rem;
    background: transparent;
    top: 1.7rem;
    right: rem(72);
    cursor: pointer;
    z-index: 999999999;
    
    &::before {
        content: "";
        display: inline-block;
        height: 100%;
        width: 100%;
        border-radius: 3rem;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        transition: all .4s;
    }
    
    // Hover blink effect for no-phone and no-touch device
    @media only screen and (min-width: $small-device), (any-hover: hover) {
        
        &:hover {

            &::before {
                transform: scale(1.6);
                background: $primary-color;
                opacity: 0;
            }
        }

        &:focus {
            outline: 1px solid $primary-color;
            transform: scale(1.1) translateY(-0.2rem);
            transition: all 0.2s linear;
        }
    }

    // Position for small device

    @media only screen and (max-width: $large-device) {
        right: rem(22) !important;
    }

    @media only screen and (max-width: $small-device) {
        top: rem(27) !important;
        right: rem(40) !important;
    }
}

// Style position

div.hamburger {
    top: 1.3rem !important;
    right: rem(60) !important;
    left: auto !important;
    overflow: hidden !important;
    height: 70px !important;;
    width: 70px !important; 

    @media only screen and (max-width: $large-device) {
        right: rem(10) !important;
    }


    @media only screen and (max-width: $small-device) {
        right: rem(30) !important;
    }
}

// Hamburger style

.hamburger {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 20px;
    left: 20px;
    box-sizing: border-box;
    cursor: pointer;
    visibility: visible;
    z-index: 99999999;
    

    &__icon {
        transition: .2s;
        stroke: color($hamburger, primary, color);
    }

    // Hamburger first line

    &__top {
        opacity: 1;
        transition: opacity 0s .2s;
        
        line {
            transition: transform .2s .2s;
            transform: translateY(0px);
        }
    }

    // Hamburger last line

    &__bottom {
        opacity: 0;
        transition: opacity 0s .2s;
        
        line {
            transition: transform .2s;
            transform: rotate(0deg);
        
        }
    }
    
    // Style for open hamburger

    &--open  {
      
        g:first-child {
            opacity: 0;
        
            line {
                transition: transform .2s;
            }

            line:first-child {
                transform: translateY(7px);
            }
                
            line:last-child {
                transform: translateY(-7px);
            }
        }
        

        g:last-child{
            
            stroke: $light-color;
            opacity: 1;
            transform: translateX(70px) rotate(90deg);

            line {
                transition: transform .2s .2s;
            }

            line:first-child {
                transform: scale(0.9) rotate(49deg) translateX(10px);
                transform-origin: center left;
                
            }
                
            line:last-child {
                transform: scale(0.9) rotate(-46deg)  translateX(-10px);
                transform-origin: center right;
            }
        }  
    }
}

.hamburger-htInner {
    overflow: hidden !important;
}

// Style for section

.hamburger-htHeader {

    &.default {

        .hamburger__icon {
            filter: drop-shadow(  0 5px 13px  color($hamburger, primary, shadow));
            stroke: color($hamburger, primary, color);
        }
    }

    // Dark style

    &.black {

        .hamburger__icon {
            stroke: color($hamburger, dark, color);
        }
    }

    // Light style
    
    &.white {

        .hamburger__icon {
            stroke: color($hamburger, light, color);
        }

    }

    // Vodka style

    &.vodka {

        .hamburger__icon {
            stroke: color($hamburger, vodka, color);

            @media only screen and (max-width: $extra-small-device) {
                stroke: #000000;
            }
        }
    }
}


// Static style other page

div.hamburger__hover--static {
    position: absolute;
    top: 2.2rem;
    right: rem(78) !important;
    visibility: visible;
    z-index: 999999999;
    pointer-events: auto;

    @media only screen and (max-width: $medium-device) {
        top: 2.3rem !important;
        right: rem(22) !important;
    }
}

div.hamburger--static {
    position: static !important;
    top: initial !important;
    right: initial !important;
    bottom: initial !important;
    left: initial !important;
    pointer-events: auto;
}