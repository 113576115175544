// *********************************************************
// CSS for hack browser
// *********************************************************

/* IE10 */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    
    .section {

        &--nocu {
            background-image: url("../images/background/#{color($portfolio, nocu, bg-ie)}") !important;
        }
    }
}