// *********************************************************
// Scroll down
// *********************************************************

// Animation scroll icon

@-webkit-keyframes scroll {

  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.scroll-down {
    display: block;
    font-size: rem(12);
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 5rem;
    transform: translateX(-50%);
    text-align: center;
    z-index: 99999999;

    &__link {
        display: block;
        position: relative;
        padding-top: rem(60);
        transform: translate(0, -50%);
        text-decoration: none;
        transition: opacity 0.3s;
        text-transform: uppercase;
        color: #000000;

        &:hover {
            opacity: 0.5;
        }
    }

    &__icon {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: rem(15);
        height: rem(15);
        border-left: 1px solid $primary-color;
        border-bottom: 1px solid $primary-color;
        transform: rotate(-45deg);
        animation: scroll 2s infinite;
        opacity: 0;
        box-sizing: border-box;

        &:nth-child(1) {
            animation-delay: 0s;
        }

        &:nth-child(2) {
            top: rem(16);
            animation-delay: 0.15s;
        }

        &:nth-child(3) {
            top: rem(32);
            animation-delay: 0.3s;
        }
    }

    &--hide {
        display: none;
    }
}


