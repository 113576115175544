// *********************************************************
// Sub navigation
// *********************************************************

.sub-navigation {
    align-items: center;
    max-width: 70%;
    margin: 0 auto;
    height: rem(120);
    left: 0;
    right: 0;

    @media only screen and (max-width: em(1400)) {
        max-width: 75%;
    }

    @media only screen and (max-width: $medium-device) {
        position: relative;
        max-width: calc(100% - 64px);
    }

    @media only screen and (max-width: $small-device) {
        margin-bottom: rem(40);

    }

    &__list {
        display: flex;
        align-items: center;
        height: 100%;
        color: #FFFFFF;

        @media only screen and (max-width: $medium-device) {
            flex-wrap: wrap;
            justify-content: flex-start;
        }

        @media only screen and (max-width: $small-device) {
            justify-content: space-between;
        }
    }

    &__item {

        margin-right: rem(10);

        @media only screen and (max-width: $medium-device) {
            margin: rem(10);
        }

        @media only screen and (max-width: $small-device) {
            flex-basis: 25%;
        }

        @media only screen and (max-width: $extra-small-device) {
            flex-basis: 40%;
        }
    }

    &__link,
    &__link:link,
    &__link:visited  {
        font-size: rem(16);
        font-size: 1.2vw;
        display: flex;
        align-items: center;
        font-weight: 600;

        @media only screen and (max-width: em(1400)) {
            font-size: rem(14);
        }
    
        @media only screen and (max-width: em(1150)) {
            font-size: rem(11.5);
        }

        @media only screen and (max-width: $medium-device) {
            font-size: rem(14);
        }
    }

    &__link {
        @media only screen and (min-width: $small-device), (any-hover: hover) {

            &:hover {
                color: #DBEAFF;
            }

            &:hover::before {
                box-shadow: 0 3px 6px #006EFF;
                background: $primary-color;
            }
        }
    }

    &__link::before {
        content: "";
        display: block;
        width: rem(18);
        height: rem(1);
        margin-right: rem(10);
        background: #ADACAC;
    }

    &__link--active::before {
        box-shadow: 0 3px 6px #006EFF;
        background: $primary-color;
    }
}

.sub-navigation--nav {
    display: none;
}
