// *********************************************************
// Colors
// *********************************************************
// Primary color
$primary-color: #006EFF;

// Dark color
$dark-color: #000000;

// Light color
$light-color: #FFFFFF;

// Navigation

$navigation: (
    background: (
        bg-color: #141416,
    ),

    logo: (
        color: #FFFFFF,
    )
);

// PORTFOLIO COLOR: Button, background, heading
$portfolio: (
    imhd: (
        button-bg: #E91800,
        button-color: #FFFFFF,
        bg: #FFFFFF,
    ),

    silentel: (
        button-bg: #E10C1B,
        button-color: #FFFFFF,
        bg: #000000,
    ),

    zaparkujto: (
        button-bg: #FFFFFF,
        button-color: #2AA1A9,
        bg: #FFFFFF,
    ),

    vodka: (
        heading: #003446,
        button-bg: #642915,
        button-color: #FFFFFF,
        bg: #FFFFFF,
    ),

    bethereum: (
        button-bg: #16F1FC,
        button-color: #662CC0,
        bg: #121212,
    ),

    hellenski: (
        button-bg: #000000,
        button-color: #FFFFFF,
        bg: #FFFFFF,
    ),

    fuergy: (
        button-bg: #3480FD,
        button-color: #FFFFFF,
        bg: #010101,
    ),

    promobay: (
        heading: #FFFFFF,
        button-bg: #78EAB1,
        button-color: #111320,
        bg: #000000,
    ),

    today-bank: (
        button-bg: linear-gradient(to right, #14F6B4, #2BD9E7),
        button-color: #FFFFFF,
        bg: #F4F6F9,
        hover: #14F6B4,
    ),

    emm: (
        button-bg: #FFFFFF,
        button-color: #1C67A7,
        bg: #121212,
    ),

    medialer: (
        button-bg: #964AD6,
        button-color: #FFFFFF,
        bg: #FFFFFF,
    ),

    topcoach: (
        button-bg: #FFF213,
        button-color: #131318,
        bg: #131318,
    ),

    correct-green: (
        button-bg: #1CDF15,
        button-color: #FFFFFF,
        bg: #fafafa,
    ),

    nocu: (
        button-bg: #FFFFFF,
        button-color: #372821,
        bg: #000000,
    )
);

// Hamburger color
$hamburger: (

    primary: (
        color: #006eff,
        shadow: hsla(212, 100%, 64%, 0.5),
    ),

    dark: (
        color: #000000,
    ),

    light: (
        color: #FFFFFF,
    ),

    vodka: (
        color: #642915,
    )
);

// Scroll bar 
$scroll-bar: (
    track: (
        color: #E7E7E7,
    ),
    
    thumb:(
        color: #006EFF,
    ),

    scroll: (
        color: initial,
    )
);

// Logo color
$logo: (
    
    default: (
        emblem: #006EFF,
        text: #0A213E,
    ),

    dark: (
        emblem-text: #000000,
    ),

    light: (
        emblem-text: #FFFFFF,
    ),

    vodka: (
        emblem-text: #003446,
    )
);

// Scroll button
$scroll-button: (
    default: (
        color: #FFFFFF,
        background: #006EFF,
    ),

    black: (
        color: #FFFFFF,
        background: #000000,
    ),

    white: (
        color: #000000,
        background: #FFFFFF,
    ),

    vodka: (
        color: #FFFFFF,
        background: #642915,
    )
);

// Chat button
$chat-button: (
    default: (
        color: #FFFFFF,
        background: #006EFF,
        shadow: #459CFF,
    ),

    white: (
        color: #FFFFFF,
        background: #000000,
    ),

    black: (
        color: #000000,
        background: #FFFFFF,
    ),

    vodka: (
        color: #FFFFFF,
        background: #642915,
    ),

    open-nav: (
        color: #FFFFFF,
    )
);

// Social 
$social: (
    black: (
        color: #FFFFFF,
    ),

    white: (
        color: #000000,
    )
);

// Testimonial
$testimonial: (
    job: (
        color: #7D828B,
    )
);

// Footer 
$footer: (
    sub-text: (
        color: #7D828B,
    ),

    count: (
        color: #FFFFFF,
    ),

    sub-heading: (
        color: #ADACAC,
    )
);