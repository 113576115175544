
// Settings
$preloader-size: rem(50);
$preloader-radius: 50%;

// Mixin for transition

@mixin transform($arguments){
    -webkit-transform: $arguments;
    -moz-transform: $arguments;
    -ms-transform: $arguments;
    -o-transform: $arguments;
    transform: $arguments;
}

/* Animations */
@-webkit-keyframes preloader-inside-white {

    0%{
        @include transform(scale(0, 0));
    }

    100%{
        @include transform(scale(1, 1));
    }
}
@keyframes preloader-inside-white{

    0%{
        @include transform(scale(0, 0));
    }

    100%{
        @include transform(scale(1, 1));
    }
}
@-webkit-keyframes preloader-inside-blue {

    0%{
        @include transform(scale(0, 0));
    }

    30%{
        @include transform(scale(0, 0));
    }

    100%{
        @include transform(scale(1, 1));
    }
}
@keyframes preloader-inside-blue {

    0%{
        @include transform(scale(0, 0));
    }

    30%{
        @include transform(scale(0, 0));
    }

    100%{
        @include transform(scale(1, 1));
    }
}

/* Preloader style */

.preloader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999999999;
    background: $light-color;
    text-align: center;

    // Preloader container

    .preloader-container {
        display: inline-block;
        width: $preloader-size;
        height: $preloader-size;
        margin: auto;
        position: relative;

        // Preloader animation icon

        .animated-preloader {
            display: inline-block;
            width: $preloader-size;
            height: $preloader-size;
            position: absolute;
            top: 0;
            left: 0;
            background: $primary-color;
            border-radius: $preloader-radius;

            // Preloader animation icon blink

            &:after {
                content: "";
                display: inline-block;
                width: $preloader-size;
                height: $preloader-size;
                position: absolute;
                top: 0;
                left: 0;
                border-radius: $preloader-radius;
                background: $light-color;
                -webkit-animation: preloader-inside-white 1s ease-in-out infinite;
                -ms-animation:     preloader-inside-white 1s ease-in-out infinite;
                animation:         preloader-inside-white 1s ease-in-out infinite;
            }  

            // Preloader animation icon blink
            
            &:before {
                content: "";
                display: inline-block;
                width: $preloader-size;
                height: $preloader-size;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 10;
                border-radius: $preloader-radius;
                background:$primary-color;
                -webkit-animation: preloader-inside-blue 1s ease-in-out infinite;
                -ms-animation:     preloader-inside-blue 1s ease-in-out infinite;
                animation:         preloader-inside-blue 1s ease-in-out infinite;
            }
        }
    }
}