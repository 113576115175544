.hero {
    width: 100%;
    height: 100%;
    text-align: center;
    position: relative;

    &__heading-wrapper {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 60%;
        z-index: 999999;

        @media only screen and (max-width: $extra-small-device) and (orientation: portrait) {
            top: 45%;
        }
    }

    &__text {
        margin-top: 1.5rem;
        font-size: 1.5rem;
        font-weight: bold;

        @media only screen and (max-width: $extra-small-device) {
            font-size: 1rem;
        }
    }

    &__emblem {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 90%;
        width: auto;
        margin: auto;
        z-index: 2;
        opacity: 0.7;

    }

    &__animation {
        position: absolute;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        left: 50%;
        z-index: 9;
        height: 95%;
        width: 95%;
        z-index: -1;
        visibility: visible;

        @media only screen and (min-height: em(1500)) {
            width: rem(1500);
            height: rem(1500);
        }

        @media only screen and (max-width: em(1200)) and (orientation: portrait) {
            height: 90vw;
            width: 90vw;
        }

        &--nav {
            position: fixed;
            z-index: 9999;
        }

        &--navigation {
            position: fixed;
            z-index: 9999;
        }
    }

    &--nav &__heading-wrapper {
        z-index: 9999;
    }
}

