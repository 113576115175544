// *********************************************************
// Section services
// *********************************************************

.services {

    // Hero style

    &__hero-heading {
        font-size: rem(40);
        font-size: 2.5vw;
        font-weight: 600;
        color: #FFFFFF;
        margin-bottom: 4rem;
        margin-bottom: 5vw;

        @media only screen and (max-width: $medium-device) {
            font-size: rem(30);
            margin-bottom: 2rem;
        }

        @media only screen and (max-width: $extra-small-device) {
            margin-top: 7rem;
        }
    }

    &__hero-text {
        font-size: rem(60);
        font-size: 4vw;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 1.2;

        @media only screen and (max-width: $medium-device) {
            font-size: rem(24);
            line-height: 1.6;
        }
    }

    // Services section style

    &__date {
        display: flex;
        align-items: center;
        font-size: rem(20);
        font-size: 1.3vw;
        position: relative;
        top: rem(-50);
        top: -4vw;
        color: #DBEAFF;

        &::before {
            content: "";
            display: block;
            width: rem(18);
            height: rem(1);
            margin-right: rem(20);
            background: $primary-color;
        }

        @media only screen and (max-width: $medium-device) {
            font-size: rem(20);
            top: rem(0);
            margin-bottom: rem(15);
        }
    }

    &__heading {
        font-size: rem(60);
        font-size: 4vw;
        font-weight: bold;
        color: #FFFFFF;
        margin-bottom: 2rem;
        margin-bottom: 2vw;
        line-height: 1.2;

        @media only screen and (max-width: $medium-device) {
            font-size: rem(48);
            margin-bottom: 2rem;
        }

        @media only screen and (max-width: $small-device) {
            font-size: rem(32);
        }

        @media only screen and (max-width: $extra-small-device) {
            font-size: rem(24);
        }
    }

    &__subheading {
        font-size: rem(17);
        font-size: 1.1vw;
        font-weight: 600;
        color: #FFFFFF;
        margin-bottom: 2rem;
        margin-bottom: 2vw;

        @media only screen and (max-width: $medium-device) {
            font-size: rem(17);
            margin-bottom: 2rem;
        }
    }

    &__text {
        font-size: rem(20);
        font-size: 1.3vw;
        color: #DBEAFF;
        line-height: 1.6;
        margin-bottom: 2rem;
        margin-bottom: 2vw;

        @media only screen and (max-width: $medium-device) {
            font-size: rem(16);
            margin-bottom: 2rem;
        }
    }

    
    &__text--webs {
        margin-top: rem(-170);

        @media only screen and (max-width: $large-device) {
            margin-top: rem(-100);
        }

        @media only screen and (max-width: $medium-device) {
            margin-top: 0;
        }
    }

    &__cta,
    &__cta:link,
    &__cta:visited {
        display: flex;
        align-items: center;
        font-size: rem(15);
        font-size: 1vw;
        color: $primary-color;
        cursor: pointer;

        @media only screen and (max-width: $medium-device) {
            font-size: rem(15);
        }
    }

    &__cta::after {
        content: inline-svg("<svg id='Group_3692' data-name='Group 3692' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7.989 14.099'> <path id='Path_6163' data-name='Path 6163' d='M7.707,7.707,1.6,13.817A.928.928,0,0,1,.94,14.1a.928.928,0,0,1-.658-.282.909.909,0,0,1,0-1.316L5.733,7.049.282,1.6a.909.909,0,0,1,0-1.316A.909.909,0,0,1,1.6.282L7.707,6.391A.909.909,0,0,1,7.707,7.707Z' transform='translate(0 0)' fill='#1278e0'/></svg>");
        display: block;
        margin-top: rem(1);
        width: 0.4vw;
        margin-left: 0.7vw;

        @media only screen and (max-width: $medium-device) {
            width: rem(7);
            margin-left: rem(10);
        }
    }

    &__images {
       

        @media only screen and (max-width: $small-device) {
            text-align: center;
        }
    }

    &__images--webs {
        text-align: right;

    }

    
    &__images--webs img {
        position: relative;
        height: rem(1000);
        height: 60vw;
        right: rem(-200);
        max-width: none;

        @media only screen and (max-width: $medium-device) {
            right: rem(-50);
        }

        @media only screen and (max-width: $small-device) {
            max-width: 100%;
            height: auto;
            right: 0;
        }
    }

    &__images--social img {
        height: rem(700);
        height: 50vw;
        max-width: none;

        @media only screen and (max-width: $small-device) {
            max-width: 100%;
            height: auto;
        }
    }
    
    &__images--campaings img {
        position: relative;
        right: rem(-12);
        height: rem(600);
        height: 40vw;
        max-width: none;

        @media only screen and (max-width: $small-device) {
            max-width: 100%;
            height: auto;
            right: 0;
        }
    }

    &__images--startups img {
        position: relative;
        top: rem(-100);
        left: rem(-100);
        height: rem(600);
        height: 40vw;
        max-width: none;

        @media only screen and (max-width: $large-device) {
            left: 0;    
        }

        @media only screen and (max-width: $small-device) {
            max-width: 100%;
            top: 0;
            left: 0;
            height: auto;     
        }
    }

    &__images--augment {
        position: relative;
        text-align: right;
    }

    &__images--augment img {
        height: rem(700);
        height: 50vw;
        max-width: none;

        @media only screen and (max-width: $small-device) {
            height: auto;
            max-width: 100%;
        }
    }


    // Build startups

    &__images--startup img {

        @media only screen and (max-width: $small-device) {
            max-width: rem(300);
        }
    }

    &__images--startup:hover img {

        @media only screen and (min-width: $small-device), (any-hover: hover) {
            filter: grayscale(100%);
        }
    }

    &__list {
        font-size: rem(14);
        font-size: 1vw;
        color: #DBEAFF;

        @media only screen and (max-width: $medium-device) {
            font-size: rem(16);
            margin-bottom: 2rem;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        margin-bottom: rem(12);
        margin-bottom: 0.85vw;

        @media only screen and (min-width: $small-device), (any-hover: hover) {
            
            &:hover::before {
                box-shadow: none;
            }
        }

        @media only screen and (max-width: $medium-device) {
            margin-bottom: rem(12);
        }
    }

    &__item::before {
        content: "";
        display: block;
        height: rem(9);
        height: 0.65vw;
        width: rem(9);
        width: 0.65vw;
        border-radius: 50%;
        margin-right: rem(12);
        margin-right: 0.8vw;
        background: $primary-color;
        box-shadow: 0 0 16px #006EFF;

        @media only screen and (max-width: $medium-device) {
            margin-right: rem(12);
            height: rem(8);
            width: rem(8);
        }
    }

    &__cta {

    }

    &__cta-heading {
        font-size: rem(60);
        font-size: 4vw;
        font-weight: bold;
        color: #FFFFFF;
        margin-bottom: 3rem;
        margin-bottom: 3vw;
        text-align: center;

        @media only screen and (max-width: $medium-device) {
            font-size: rem(24);
            margin-bottom: 3rem;
        }
    }

    &__cta-text {
        font-size: rem(20);
        font-size: 1.3vw;
        color: #DBEAFF;
        line-height: 1.6;
        margin-bottom: 3rem;
        margin-bottom: 3vw;
        max-width: rem(700);
        max-width: 50vw;
        margin-left: auto;
        margin-right: auto;
        text-align: center;

        @media only screen and (max-width: $medium-device) {
            font-size: rem(14);
            margin-bottom: 3rem;
            max-width: rem(720);
        }
    }

    &__cta-btn {
        text-align: center;
    }
}