// *********************************************************
// Scroll up button
// *********************************************************

div.scroll-button {
    position: fixed;
    top: auto !important;
    bottom: 2rem !important;
    left: 3.3rem !important;
    height: 3rem !important;
    width: 3rem !important;
    border-radius: 50%;
    text-align: center;
    z-index: 9999999;
    transform: scale(5);
    opacity: 0;
    transition: all 0.3s ease-in-out;
    visibility: hidden;

    
    
    &--show {
        transform: scale(1);
        opacity: 1;
        visibility: visible;
    }

    @media only screen and (max-width: $small-device) {
        left: rem(32) !important;
    }
}

.scroll-button {


    &__link {
        display: block;
        height: 3rem;
        padding: 0.75rem;
    }

    &__icon {
        max-height: 100%;
        fill: currentColor;
    }

    &--hide {
        display: none;
    }

}

div.scroll-button--primary {
    background: color($scroll-button, default, background);
    color: color($scroll-button, default, color);
    left: 4.4rem !important;

    @media only screen and (max-width: $medium-device) {
        left: 1rem !important;
    }
}

.scroll-htInner {
    overflow: hidden !important;
   
}

// Style for section

.scroll-htHeader {

    &.default {

        background: color($scroll-button, default, background);
        color: color($scroll-button, default, color);
    }
    

    &.black {
        color: color($scroll-button, black, color);
        background: color($scroll-button, black, background);
    }

    &.white {
        color: color($scroll-button, white, color);
        background: color($scroll-button, white, background);
    }

    &.vodka {
        color: color($scroll-button, vodka, color);
        background: color($scroll-button, vodka, background);
    }
}
