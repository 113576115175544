// *********************************************************
// Virtula reality
// *********************************************************

.virtual-reality {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url("../images/page/services/virtual-reality_bg.jpg");
    background-size: 80% auto;
    background-repeat: no-repeat;
    background-attachment: fixed;
    opacity: 0.8;
    padding: rem(70);

    @media only screen and (max-width: $medium-device) {
        padding: rem(30) rem(30);
        background-size: cover;
        background-attachment: initial;
    }

    @media only screen and (max-width: $small-device) {
        padding: rem(70) rem(15);
    }

    &__col {
        position: relative;
        width: 50%;
        margin: auto;


        @media only screen and (max-width: $small-device) {
            width: calc(100% - 64px);
            height: rem(550);
        }

        
        @media only screen and (max-width: $extra-small-device) {
            height: rem(450);
        }
    }

    &__col::after {
        content: "";
        display: block;
        padding-bottom: 100%;

        @media only screen and (max-width: $small-device) {
            display: none;
        }
    }

    &__images {
        position: absolute;
        top: rem(100);
        width: 60%;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 99;

        @media only screen and (max-width: $large-device) {
            top: rem(70);
        }

        @media only screen and (max-width: $medium-device) {
            top: rem(0);
        }

        @media only screen and (max-width: $small-device) {
            width: 95%;
        }
    }

    &__text-wrapper {
        color: #FFFFFF;
        text-align: center;
    }

    &__heading {
        font-size: rem(60);
        font-size: 4vw;
        font-weight: bold;
        margin-bottom: 2rem;
        margin-bottom: 2vw;

        @media only screen and (max-width: $medium-device) {
            font-size: rem(32);
            margin-bottom: 2rem;
        }
    }

    &__text {
        font-size: rem(14);
        font-size: 1vw;
        max-width: rem(420);
        max-width: 30vw;
        line-height: 1.6;

        @media only screen and (max-width: $medium-device) {
            font-size: rem(14);
            max-width: rem(420);
        }
    }
}