// *********************************************************
// Buttons
// *********************************************************

.btn {
    // Button default style

    &,
    &:link,
    &:visited {
        display: inline-block;
        position: relative;
        font: {
            family: inherit;
            family: "Bebas Neue";
            size: rem(16);
            weight: bold;
        }
        letter-spacing: 0.1em;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        text-decoration: none;
        text-transform: uppercase;
        outline: none;
        cursor: pointer;
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        border: 1px solid transparent;
        box-shadow: none;
        touch-action: manipulation;
        -webkit-appearance: none;
        // padding: rem(19.2) rem(65);
        padding: rem(21) rem(65);
        min-width: rem(220);
        border-radius: 3em;
        color: #000000;
        background: #FFFFFF;
        z-index: 9999;

        &::before {
            content: "";
            display: inline-block;
            height: 100%;
            width: 100%;
            border-radius: 3rem;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            transition: all .4s;
        }

        @media only screen and (max-width: $large-device) {
            padding: rem(14.2) rem(20);
            min-width: rem(150);
            font-size: 0.8rem;
    
        }
    }

    // Button primary style

    &--primary,
    &--primary:link,
    &--primary:visited {
        color: #FFFFFF;
        background: $primary-color;

    }

    &--primary:hover {
        // Hover button style for no-phone and no-touch device

        @media only screen and (min-width: $small-device), (any-hover: hover) {
            color: #FFFFFF;
            border-color: darken($primary-color, 7%);
            background: darken($primary-color, 7%);
            box-shadow: 0 2px 4px 0 rgba(0,0,0,.3); 
    
            // Blink effect

            &::before {

                transform: scaleX(1.4) scaleY(1.6);
                background: darken($primary-color, 7%);
                opacity: 0;
            }
        }
    }

    &--primary:focus {
        outline: 1px solid darken($primary-color, 7%);
        transform: scale(1.1) translateY(-0.2rem);
        transition: transform 0.2s linear;
    }

    &--primary:active {
        box-shadow: 0 3px 10px 0 rgba(0,0,0,.5);
    }

    // Button primary box shadow

    &--shadow,
    &--shadow:link,
    &--shadow:visited {
        box-shadow: 0 5px 20px #459CFF;
    }

    // Button with arrow style

    &--arrow,
    &--arrow:link,
    &--arrow:visited {
       
        padding-left: calc(4.063em - 3rem);
        min-width: rem(280);
        width: auto;

        // Arrow

        &::after {
            content: url("../images/icon/left-arrow.svg");
            display: inline-block;
            vertical-align: middle;
            position: relative;
            top: em(3);
            right: -3rem;
            width: 1.3em;
        }

        @media only screen and (max-width: $small-device) {
            min-width: auto;
            padding-left: rem(40);
            padding-right: rem(40);


            &::after {
                top: em(1);
                right: rem(-20);
            }
        }
    }

    // Button secondary style

    &--secondary,
    &--secondary:link,
    &--secondary:visited {
        border: 1px solid #000000;
    }

    &--secondary:hover {
        // Hover button style for no-phone and no-touch device

        @media only screen and (min-width: $small-device), (any-hover: hover) {
            color: #FFFFFF;
            border-color: $primary-color;
            background: $primary-color;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,.3); 
    
            // Blink effect

            &::before {

                transform: scaleX(1.4) scaleY(1.6);
                background: $primary-color;
                opacity: 0;
            }
        }
    }

    &--secondary:focus {
        outline: 1px solid darken($primary-color, 7%);
        transform: scale(1.1) translateY(-0.2rem);
        transition: transform 0.2s linear;
    }

    &--secondary:active {
        box-shadow: 0 3px 10px 0 rgba(0,0,0,.5);
    }

    // Button imhd style

    &--imhd,
    &--imhd:link,
    &--imhd:visited {
        color: color($portfolio, imhd, button-color);
        background-color: color($portfolio, imhd, button-bg);
    }

    &--imhd:hover {
        // Hover button style for no-phone and no-touch device

        @media only screen and (min-width: $small-device), (any-hover: hover) {
            color: color($portfolio, imhd, button-bg);
            border-color: darken(color($portfolio, imhd, button-bg), 7%);
            // background: darken(color($portfolio, imhd, button-bg), 7%);
            background: transparent;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,.3); 
    
            // Blink effect

            &::before {

                transform: scaleX(1.4) scaleY(1.6);
                background: darken(color($portfolio, imhd, button-bg), 7%);
                opacity: 0;
            }
        }
    }

    &--imhd:focus {
        color: color($portfolio, imhd, button-bg);
        border-color: darken(color($portfolio, imhd, button-bg), 7%);
        background: transparent;
        outline: 1px solid darken(color($portfolio, imhd, button-bg), 7%);
        transform: scale(1.1) translateY(-0.2rem);
        transition: transform 0.2s linear;
    }

    &--imhd:active {
        box-shadow: 0 3px 10px 0 rgba(0,0,0,.5);
    }

    // Button silentel style

    &--silentel,
    &--silentel:link,
    &--silentel:visited {
        color: color($portfolio, silentel, button-color);
        background: color($portfolio, silentel, button-bg);
    }

    &--silentel:hover {
        // Hover button style for no-phone and no-touch device

        @media only screen and (min-width: $small-device), (any-hover: hover) {
            color: color($portfolio, silentel, button-bg);
            border-color: color($portfolio, silentel, button-bg);
            // background: darken(color($portfolio, silentel, button-bg), 7%);
            background: transparent;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,.3); 
    
            // Blink effect

            &::before {

                transform: scaleX(1.4) scaleY(1.6);
                background: darken(color($portfolio, silentel, button-bg), 7%);
                opacity: 0;
            }
        }
    }

    &--silentel:focus {
        color: color($portfolio, silentel, button-bg);
        border-color: color($portfolio, silentel, button-bg);
        outline: 1px solid darken(color($portfolio, silentel, button-bg), 7%);
        background: transparent;
        transform: scale(1.1) translateY(-0.2rem);
        transition: transform 0.2s linear;
    }

    &--silentel:active {
        box-shadow: 0 3px 10px 0 rgba(0,0,0,.5);
    }


    // Button zaparkujto style

    &--zaparkujto,
    &--zaparkujto:link,
    &--zaparkujto:visited {
        color: color($portfolio, zaparkujto, button-bg);
        background: color($portfolio, zaparkujto, button-color);

    }

    &--zaparkujto:hover {
        // Hover button style for no-phone and no-touch device

        @media only screen and (min-width: $small-device), (any-hover: hover) {
            color: color($portfolio, zaparkujto, button-color);
            border-color: color($portfolio, zaparkujto, button-color);
            // background: darken(color($portfolio, silentel, button-bg), 7%);
            background: transparent;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,.3); 
    
            // Blink effect

            &::before {

                transform: scaleX(1.4) scaleY(1.6);
                background: darken(color($portfolio, zaparkujto, button-bg), 7%);
                opacity: 0;
            }
        }
    }

    &--zaparkujto:focus {
        color: color($portfolio, zaparkujto, button-color);
        border-color: color($portfolio, zaparkujto, button-color);
        outline: 1px solid color($portfolio, zaparkujto, button-color);
        background: transparent;
        transform: scale(1.1) translateY(-0.2rem);
        transition: transform 0.2s linear;
    }

    &--zaparkujto:active {
        box-shadow: 0 3px 10px 0 rgba(0,0,0,.5);
    }

    // Button vodka style

    &--vodka,
    &--vodka:link,
    &--vodka:visited {
        color: color($portfolio, vodka, button-color);
        background: color($portfolio, vodka, button-bg);
    }

    &--vodka:hover {
        // Hover button style for no-phone and no-touch device

        @media only screen and (min-width: $small-device), (any-hover: hover) {
            color: color($portfolio, vodka, button-bg);
            border-color: color($portfolio, vodka, button-bg);
            // background: darken(color($portfolio, silentel, button-bg), 7%);
            background: transparent;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,.3); 
    
            // Blink effect

            &::before {

                transform: scaleX(1.4) scaleY(1.6);
                background: darken(color($portfolio, vodka, button-bg), 7%);
                opacity: 0;
            }
        }
    }

    &--vodka:focus {
        color: color($portfolio, vodka, button-bg);
        border-color: color($portfolio, vodka, button-bg);
        outline: 1px solid darken(color($portfolio, vodka, button-bg), 7%);
        background: transparent;
        transform: scale(1.1) translateY(-0.2rem);
        transition: transform 0.2s linear;
    }

    &--vodka:active {
        box-shadow: 0 3px 10px 0 rgba(0,0,0,.5);
    }

    // Button bethereum style

    &--bethereum,
    &--bethereum:link,
    &--bethereum:visited {
        color: color($portfolio, bethereum, button-color);
        background: color($portfolio, bethereum, button-bg);
    }

    &--bethereum:hover {
        // Hover button style for no-phone and no-touch device

        @media only screen and (min-width: $small-device), (any-hover: hover) {
            color: color($portfolio, bethereum, button-bg);
            border-color: color($portfolio, bethereum, button-bg);
            // background: darken(color($portfolio, silentel, button-bg), 7%);
            background: transparent;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,.3); 
    
            // Blink effect

            &::before {

                transform: scaleX(1.4) scaleY(1.6);
                background: darken(color($portfolio, bethereum, button-bg), 7%);
                opacity: 0;
            }
        }
    }

    &--bethereum:focus {
        color: color($portfolio, bethereum, button-bg);
        border-color: color($portfolio, bethereum, button-bg);
        outline: 1px solid darken(color($portfolio, bethereum, button-bg), 7%);
        background: transparent;
        transform: scale(1.1) translateY(-0.2rem);
        transition: transform 0.2s linear;
    }

    &--bethereum:active {
        box-shadow: 0 3px 10px 0 rgba(0,0,0,.5);
    }

    // Button hellenski style

    &--hellenski,
    &--hellenski:link,
    &--hellenski:visited {
        color: color($portfolio, hellenski, button-color);
        background: color($portfolio, hellenski, button-bg);
    }

    &--hellenski:hover {
        // Hover button style for no-phone and no-touch device

        @media only screen and (min-width: $small-device), (any-hover: hover) {
            color: color($portfolio, hellenski, button-bg);
            border-color: color($portfolio, hellenski, button-bg);
            // background: darken(color($portfolio, silentel, button-bg), 7%);
            background: transparent;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,.3); 
    
            // Blink effect

            &::before {

                transform: scaleX(1.4) scaleY(1.6);
                background: darken(color($portfolio, hellenski, button-bg), 7%);
                opacity: 0;
            }
        }
    }

    &--hellenski:focus {
        color: color($portfolio, hellenski, button-bg);
        border-color: color($portfolio, hellenski, button-bg);
        outline: 1px solid darken(color($portfolio, hellenski, button-bg), 7%);
        background: transparent;
        transform: scale(1.1) translateY(-0.2rem);
        transition: transform 0.2s linear;
    }

    &--hellenski:active {
        box-shadow: 0 3px 10px 0 rgba(0,0,0,.5);
    }

    // Button fuergy style

    &--fuergy,
    &--fuergy:link,
    &--fuergy:visited {
        color: color($portfolio, fuergy, button-color);
        background: color($portfolio, fuergy, button-bg);
    }

    &--fuergy:hover {
        // Hover button style for no-phone and no-touch device

        @media only screen and (min-width: $small-device), (any-hover: hover) {
            color: color($portfolio, fuergy, button-bg);
            border-color: color($portfolio, fuergy, button-bg);
            // background: darken(color($portfolio, silentel, button-bg), 7%);
            background: transparent;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,.3); 
    
            // Blink effect

            &::before {

                transform: scaleX(1.4) scaleY(1.6);
                background: darken(color($portfolio, fuergy, button-bg), 7%);
                opacity: 0;
            }
        }
    }

    &--fuergy:focus {
        color: color($portfolio, fuergy, button-bg);
        border-color: color($portfolio, fuergy, button-bg);
        outline: 1px solid darken(color($portfolio, fuergy, button-bg), 7%);
        background: transparent;
        transform: scale(1.1) translateY(-0.2rem);
        transition: transform 0.2s linear;
    }

    &--fuergy:active {
        box-shadow: 0 3px 10px 0 rgba(0,0,0,.5);
    }

    // Button promobay style

    &--promobay,
    &--promobay:link,
    &--promobay:visited {
        color: color($portfolio, promobay, button-color);
        background: color($portfolio, promobay, button-bg);
    }

    &--promobay:hover {
        // Hover button style for no-phone and no-touch device

        @media only screen and (min-width: $small-device), (any-hover: hover) {
            color: color($portfolio, promobay, button-bg);
            border-color: color($portfolio, promobay, button-bg);
            // background: darken(color($portfolio, silentel, button-bg), 7%);
            background: transparent;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,.3); 
    
            // Blink effect

            &::before {

                transform: scaleX(1.4) scaleY(1.6);
                background: darken(color($portfolio, promobay, button-bg), 7%);
                opacity: 0;
            }
        }
    }

    &--promobay:focus {
        color: color($portfolio, promobay, button-bg);
        border-color: color($portfolio, promobay, button-bg);
        outline: 1px solid darken(color($portfolio, promobay, button-bg), 7%);
        background: transparent;
        transform: scale(1.1) translateY(-0.2rem);
        transition: transform 0.2s linear;
    }

    &--promobay:active {
        box-shadow: 0 3px 10px 0 rgba(0,0,0,.5);
    }

    // Button today bank style

    &--today-bank,
    &--today-bank:link,
    &--today-bank:visited {
        color: color($portfolio, today-bank, button-color);
        background-image: color($portfolio, today-bank, button-bg);
        background-size: 120%;
        background-position: center;
    }

    &--today-bank:hover {
        // Hover button style for no-phone and no-touch device

        @media only screen and (min-width: $small-device), (any-hover: hover) {
            color: color($portfolio, today-bank, hover);
            border-color: color($portfolio, today-bank, hover);
            // background: darken(color($portfolio, silentel, button-bg), 7%);
            background: transparent;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,.3); 
    
            // Blink effect

            &::before {

                transform: scaleX(1.4) scaleY(1.6);
                background: darken(color($portfolio, today-bank, hover), 7%);
                opacity: 0;
            }
        }
    }

    &--today-bank:focus {
        color: color($portfolio, today-bank, hover);
        border-color: color($portfolio, today-bank, hover);
        outline: 1px solid darken(color($portfolio, today-bank,hover), 7%);
        background: transparent;
        transform: scale(1.1) translateY(-0.2rem);
        transition: transform 0.2s linear;
    }


    &--today-bank:active {
        box-shadow: 0 3px 10px 0 rgba(0,0,0,.5);
    }


    // Button emm style

    &--emm,
    &--emm:link,
    &--emm:visited {
        color: color($portfolio, emm, button-color);
        background: color($portfolio, emm, button-bg);
    }

    &--emm:hover {
        // Hover button style for no-phone and no-touch device

        @media only screen and (min-width: $small-device), (any-hover: hover) {
            color: color($portfolio, emm, button-bg);
            border-color: color($portfolio, emm, button-bg);
            // background: darken(color($portfolio, silentel, button-bg), 7%);
            background: transparent;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,.3); 
    
            // Blink effect

            &::before {

                transform: scaleX(1.4) scaleY(1.6);
                background: darken(color($portfolio, emm, button-bg), 7%);
                opacity: 0;
            }
        }
    }

    &--emm:focus {
        color: color($portfolio, emm, button-bg);
        border-color: color($portfolio, emm, button-bg);
        outline: 1px solid darken(color($portfolio, emm, button-bg), 7%);
        background: transparent;
        transform: scale(1.1) translateY(-0.2rem);
        transition: transform 0.2s linear;
    }

    &--emm:active {
        box-shadow: 0 3px 10px 0 rgba(0,0,0,.5);
    }

    // Button medialer style

    &--medialer,
    &--medialer:link,
    &--medialer:visited {
        color: color($portfolio, medialer, button-color);
        background: color($portfolio, medialer, button-bg);
    }

    &--medialer:hover {
        // Hover button style for no-phone and no-touch device

        @media only screen and (min-width: $small-device), (any-hover: hover) {
            color: color($portfolio, medialer, button-bg);
            border-color: color($portfolio, medialer, button-bg);
            // background: darken(color($portfolio, silentel, button-bg), 7%);
            background: transparent;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,.3); 
    
            // Blink effect

            &::before {

                transform: scaleX(1.4) scaleY(1.6);
                background: darken(color($portfolio, medialer, button-bg), 7%);
                opacity: 0;
            }
        }
    }

    &--medialer:focus {
        color: color($portfolio, medialer, button-bg);
        border-color: color($portfolio, medialer, button-bg);
        outline: 1px solid darken(color($portfolio, medialer, button-bg), 7%);
        background: transparent;
        transform: scale(1.1) translateY(-0.2rem);
        transition: transform 0.2s linear;
    }

    &--medialer:active {
        box-shadow: 0 3px 10px 0 rgba(0,0,0,.5);
    }

    // Button topcoach style
        
    &--topcoach,
    &--topcoach:link,
    &--topcoach:visited {
        color: color($portfolio, topcoach, button-color);
        background: color($portfolio, topcoach, button-bg);
    }

    &--topcoach:hover {
        // Hover button style for no-phone and no-touch device

        @media only screen and (min-width: $small-device), (any-hover: hover) {
            color: color($portfolio, topcoach, button-bg);
            border-color: color($portfolio, topcoach, button-bg);
            // background: darken(color($portfolio, silentel, button-bg), 7%);
            background: transparent;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,.3); 
    
            // Blink effect

            &::before {

                transform: scaleX(1.4) scaleY(1.6);
                background: darken(color($portfolio, topcoach, button-bg), 7%);
                opacity: 0;
            }
        }
    }

    &--topcoach:focus {
        color: color($portfolio, topcoach, button-bg);
        border-color: color($portfolio, topcoach, button-bg);
        outline: 1px solid darken(color($portfolio, topcoach, button-bg), 7%);
        background: transparent;
        transform: scale(1.1) translateY(-0.2rem);
        transition: transform 0.2s linear;
    }

    &--topcoach:active {
        box-shadow: 0 3px 10px 0 rgba(0,0,0,.5);
    }

    // Button correct green style

    &--correct-green,
    &--correct-green:link,
    &--correct-green:visited {
        color: color($portfolio, correct-green, button-color);
        background: color($portfolio, correct-green, button-bg);
    }

    &--correct-green:hover {
        // Hover button style for no-phone and no-touch device

        @media only screen and (min-width: $small-device), (any-hover: hover) {
            color: color($portfolio, correct-green, button-bg);
            border-color: color($portfolio, correct-green, button-bg);
            // background: darken(color($portfolio, silentel, button-bg), 7%);
            background: transparent;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,.3); 
    
            // Blink effect

            &::before {

                transform: scaleX(1.4) scaleY(1.6);
                background: darken(color($portfolio, correct-green, button-bg), 7%);
                opacity: 0;
            }
        }
    }

    &--correct-green:focus {
        color: color($portfolio, correct-green, button-bg);
        border-color: color($portfolio, correct-green, button-bg);
        outline: 1px solid darken(color($portfolio, correct-green, button-bg), 7%);
        background: transparent;
        transform: scale(1.1) translateY(-0.2rem);
        transition: transform 0.2s linear;
    }

    &--correct-green:active {
        box-shadow: 0 3px 10px 0 rgba(0,0,0,.5);
    }

    // Button nocu style

    &--nocu,
    &--nocu:link,
    &--nocu:visited {
        color: color($portfolio, nocu, button-color);
        background: color($portfolio, nocu, button-bg);
    }

    &--nocu:hover {
        // Hover button style for no-phone and no-touch device

        @media only screen and (min-width: $small-device), (any-hover: hover) {
            color: color($portfolio, nocu, button-bg);
            border-color: color($portfolio, nocu, button-bg);
            // background: darken(color($portfolio, silentel, button-bg), 7%);
            background: transparent;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,.3); 
    
            // Blink effect

            &::before {

                transform: scaleX(1.4) scaleY(1.6);
                background: darken(color($portfolio, nocu, button-bg), 7%);
                opacity: 0;
            }
        }
    }

    &--nocu:focus {
        color: color($portfolio, nocu, button-bg);
        border-color: color($portfolio, nocu, button-bg);
        outline: 1px solid color($portfolio, nocu, button-bg);
        background: transparent;
        transform: scale(1.1) translateY(-0.2rem);
        transition: transform 0.2s linear;
    }

    &--nocu:active {
        box-shadow: 0 3px 10px 0 rgba(0,0,0,.5);
    }

    &::-moz-focus-inner {
        border: 0;
    }

    // Style for disabled 
    
    &:disabled,
    &[aria-disabled="true"] {
        color: rgba(#FFFFFF, 0.8);
        background: #AFAFAF;
        border-color: transparent;
        pointer-events: none;
        cursor: not-allowed;

        &::before {
            display: none;
        }
    }
}
