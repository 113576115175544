// *********************************************************
// Navigation
// *********************************************************

.nav-social {
    right: -100%;
}

.language--nav {
    left: 5rem;
}

.navigation {
    position: absolute;
    top: 0;
    width: 100%;
    overflow-y: auto;
    z-index: 999999;
    height: 100vh;
    left: 0;
    visibility: hidden;

    &::before {
        content: "";
        display: block;
        position: fixed;
        top: 0;
        right: 0;
        height: 3rem;
        width: 3rem;
        border-radius: 50%;
        z-index: 9;
        background: color($navigation, background, bg-color);
        transform: scale(1);
        opacity: 0;

    }

    &::after {
        content: "";
        display: block;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background-image: url("../images/background/black.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        height: 90%;
        width: 90%;
        z-index: 999;
        opacity: 0;

        @media only screen and (max-width: $medium-device), (max-height: em(550)) {
            height: 100%;
            width: 100%;
        }
    }

    &__logo-wrapper {
        color: color($navigation, logo, color);
        width: rem(75);
        height: rem(45);
        position: absolute;
        top: -100%;
        left: 1.6rem !important;
        z-index: 99999;
        padding: 0 1rem;
        overflow: hidden;

        @media only screen and (min-width: $small-device), (any-hover: hover) {

            &:hover .navigation__logo {
                opacity: 0;
                visibility: hidden;
                transform: perspective(200px) translate3d(-120%, 0, 0);
            }

            &:hover svg:last-child {
                opacity: 1;
                visibility: visible;
                transform: perspective(200px) translate3d(0%, 0, 0);
            }
        }

        svg:last-child  {
            position: absolute;
            top: 0;
            opacity: 0;
            visibility: hidden;
            transition: opacity 1s ease,transform 0.3s ease,visibility 0.1s ease;
            transform: perspective(200px) translate3d(120%, 0, 0);
        }
    }

    &__logo {
        transition: opacity 0.2s ease,transform 0.3s ease,visibility 0.1s ease;
    }

    &__list {
        position: absolute;
        top: 50%;
        padding: 5rem 0;
        left: -200%;
        font-size: 2.5rem;
        font-size: 2.5vw;
        font-weight: bold;
        text-align: center;
        width: 100%;
        z-index: 9999;
        transform: translateY(-50%);
        // background: red;

        @media only screen and (max-width: $large-device)  {
            font-size: 2rem;
        }

        @media only screen and (max-width: $extra-small-device)  {
            font-size: 1.5rem;
        }

        @media only screen and (max-height: em(450)) and (orientation: landscape) {
            top: 0;
            transform: translateY(0%);
        }
    }

    &__item {

        &:not(:last-child) {
            margin-bottom: 0.8em;

            @media only screen and (max-width: $small-device)  {
                margin-bottom: 2rem;
            }
        }
    }

    &__link,
    &__link:link,
    &__link:visited {
        color: #FFFFFF;

        @media only screen and (min-width: $small-device), (any-hover: hover) {

            &:hover {
                color: $primary-color;
            }
        }

        @media only screen and (max-width: $small-device)  {
            padding: 0.5rem;
        }
    }

    &__link--active,
    &__link--active:link,
    &__link--active:visited {
        color: $primary-color;
    }

    &__animation {
        position: fixed;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        left: 50%;
        z-index: 9995;
        height: 95%;
        width: 95%;

        @media only screen and (min-height: em(1500)) {
            width: rem(1500);
            height: rem(1500);
        }

        @media only screen and (max-width: em(1200)) and (orientation: portrait) {
            height: 90vw;
            width: 90vw;
        }
    }

    // Open navigaton

    &--open {
        visibility: visible;
        position: fixed;
        
        .navigation__heading {
            top: 6rem;
            transition: top 0.2s cubic-bezier(0, 1, 1, 1) 1s;
        }

        .navigation__list {
            left: 0;
            transition: left 0.2s cubic-bezier(0, 1, 1, 1) 1s;
        }

        .navigation__logo-wrapper {
            top: 1.75rem !important;
            left: 0;
            transition: top 0.2s cubic-bezier(0, 1.18, 1, 1) 0.8s;
        }

        .nav-social {
            right: rem(89);
            transition: right 0.2s cubic-bezier(0, 1.18, 1, 1) 1.2s;

            
            @media only screen and (max-width: $large-device) {
                right: rem(45) !important;
            }
        }
    }

    &--open::before {
        opacity: 1;
        transition: all 0.6s ease-in-out;

        @media only screen and (min-width: $extra-large-device) {
            transform: scale(300);
        }

        @media only screen and (max-width: $extra-large-device) {
            transform: scale(80);
            transition: all 0.3s ease-in-out;
        }

        @media only screen and (max-width: $small-device) {
            transform: scale(60);
            transition: all 0.2s ease-in-out;
        }
            
    }

    &--open::after {
        opacity: 1;
        transition: all 1s ease-in-out 1s;
    }
}

// Remove scroll for body if nav is open

.body {

    &--nav-open {
        overflow: hidden;
    }
}
