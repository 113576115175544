// *********************************************************
// Logo
// *********************************************************
// Logo services style

.logo--services {
    position: static;
    z-index: 99;
    visibility: visible;
    pointer-events: auto;
  

    @media only screen and (max-width: $medium-device) {
        width: auto;
        overflow: hidden;

        &-text {
            display: none;
        }
    }
   
}

.logo--services svg {
    // height: 8.2rem;
    width: 14rem;
    margin-left: -2.5rem;
    margin-bottom: -1rem;

    @media only screen and (max-width: $medium-device) {
        margin-left: -1.7rem;
    }
}

div.logo {
    top: rem(24) !important;
    left: rem(50) !important;
    bottom: auto !important;
    right: auto !important;
    width: 180px !important;
    height: rem(53.5);


    @media only screen and (max-width: $small-device) {
        top: rem(25) !important;
        left: rem(30) !important;
    }
}

.logo {
    position: fixed;
    cursor: pointer;
    transition: all 0.2s linear;
    backface-visibility: hidden;
    z-index: 9999999;


    &__wrapper {
        position: relative;
 

        svg:first-child {
            transition: opacity 0.2s ease,transform 0.3s ease,visibility 0.1s ease;
        }

        svg:last-child  {
            position: absolute;
            top: -2.29rem;
            z-index: 999999;
            opacity: 0;
            visibility: hidden;
            transition: opacity 1s ease,transform 0.3s ease,visibility 0.1s ease;
            transform: perspective(200px) translate3d(120%, 0, 0);
        }

        svg:last-child path {
            fill: currentColor;
            color: $primary-color;
        }

        svg:last-child path:last-child {
            fill: currentColor;
            color: #0A213E;
        }
    }

    &__emblem {
        position: relative;
        fill: currentColor;
        color: $primary-color;
        position: relative;
    }

    &__text {
        fill: currentColor;
        color: #0A213E;

    }

    &--hide {
        display: none;
    }

    &:hover .logo__wrapper {
       

        svg:first-child {
            visibility: visible;
            opacity: 0;
            transform: perspective(200px) translate3d(-120%, 0, 0);
        }

        svg:last-child {
            opacity: 1;
            visibility: visible;
            transform: perspective(200px) translate3d(0%, 0, 0);
           
        }
    }

}

.logo__hover  {
    transform: translateY(35px) translateX(43px);

    &__emblem--hover {

    }

}

.logo-htInner {
    overflow: hidden !important;
}


// Logo style for section

.logo-htHeader {

    &.default {

        .logo__wrapper svg:last-child path {
            fill: currentColor;
            color: #000000;
        }

        .logo__emblem {
            color: color($logo, default, emblem);
        }

        .logo__text {
            color: color($logo, default, text);
        }
    }

    &.black {

        .logo__emblem,
        .logo__text {
            color: color($logo, dark, emblem-text);
        }
    }

    &.white {

        .logo__wrapper svg:last-child path:last-child {
            fill: currentColor;
            color: #FFFFFF;
        }

        .logo__emblem,
        .logo__text {
            color: color($logo, light, emblem-text);
        }
    }

    &.vodka {

        .logo__emblem,
        .logo__text {
            color: color($logo, vodka, emblem-text);
        }
    }
}

