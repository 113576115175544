// *********************************************************
// Typography
// *********************************************************

// FONTS POPPINS
// font path
$font-path: "../../fonts";
@font-face {
    font-family: "Poppins";
    src: url("#{$font-path}/Poppins-Italic.eot");
    src: url("#{$font-path}/Poppins-Italic.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/Poppins-Italic.woff2") format("woff2"),
        url("#{$font-path}/Poppins-Italic.woff") format("woff"),
        url("#{$font-path}/Poppins-Italic.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "Poppins";
    src: url("#{$font-path}/Poppins-SemiBoldItalic.eot");
    src: url("#{$font-path}/Poppins-SemiBoldItalic.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/Poppins-SemiBoldItalic.woff2") format("woff2"),
        url("#{$font-path}/Poppins-SemiBoldItalic.woff") format("woff"),
        url("#{$font-path}/Poppins-SemiBoldItalic.ttf") format("truetype");
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: "Poppins";
    src: url("#{$font-path}/Poppins-LightItalic.eot");
    src: url("#{$font-path}/Poppins-LightItalic.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/Poppins-LightItalic.woff2") format("woff2"),
        url("#{$font-path}/Poppins-LightItalic.woff") format("woff"),
        url("#{$font-path}/Poppins-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Poppins";
    src: url("#{$font-path}/Poppins-ExtraLightItalic.eot");
    src: url("#{$font-path}/Poppins-ExtraLightItalic.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/Poppins-ExtraLightItalic.woff2") format("woff2"),
        url("#{$font-path}/Poppins-ExtraLightItalic.woff") format("woff"),
        url("#{$font-path}/Poppins-ExtraLightItalic.ttf") format("truetype");
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: "Poppins";
    src: url("#{$font-path}/Poppins-Thin.eot");
    src: url("#{$font-path}/Poppins-Thin.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/Poppins-Thin.woff2") format("woff2"),
        url("#{$font-path}/Poppins-Thin.woff") format("woff"),
        url("#{$font-path}/Poppins-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url("#{$font-path}/Poppins-Medium.eot");
    src: url("#{$font-path}/Poppins-Medium.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/Poppins-Medium.woff2") format("woff2"),
        url("#{$font-path}/Poppins-Medium.woff") format("woff"),
        url("#{$font-path}/Poppins-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url("#{$font-path}/Poppins-BlackItalic.eot");
    src: url("#{$font-path}/Poppins-BlackItalic.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/Poppins-BlackItalic.woff2") format("woff2"),
        url("#{$font-path}/Poppins-BlackItalic.woff") format("woff"),
        url("#{$font-path}/Poppins-BlackItalic.ttf") format("truetype");
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: "Poppins";
    src: url("#{$font-path}/Poppins-ExtraLight.eot");
    src: url("#{$font-path}/Poppins-ExtraLight.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/Poppins-ExtraLight.woff2") format("woff2"),
        url("#{$font-path}/Poppins-ExtraLight.woff") format("woff"),
        url("#{$font-path}/Poppins-ExtraLight.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url("#{$font-path}/Poppins-ThinItalic.eot");
    src: url("#{$font-path}/Poppins-ThinItalic.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/Poppins-ThinItalic.woff2") format("woff2"),
        url("#{$font-path}/Poppins-ThinItalic.woff") format("woff"),
        url("#{$font-path}/Poppins-ThinItalic.ttf") format("truetype");
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: "Poppins";
    src: url("#{$font-path}/Poppins-Light.eot");
    src: url("#{$font-path}/Poppins-Light.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/Poppins-Light.woff2") format("woff2"),
        url("#{$font-path}/Poppins-Light.woff") format("woff"),
        url("#{$font-path}/Poppins-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url("#{$font-path}/Poppins-Bold.eot");
    src: url("#{$font-path}/Poppins-Bold.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/Poppins-Bold.woff2") format("woff2"),
        url("#{$font-path}/Poppins-Bold.woff") format("woff"),
        url("#{$font-path}/Poppins-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url("#{$font-path}/Poppins-Black.eot");
    src: url("#{$font-path}/Poppins-Black.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/Poppins-Black.woff2") format("woff2"),
        url("#{$font-path}/Poppins-Black.woff") format("woff"),
        url("#{$font-path}/Poppins-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url("#{$font-path}/Poppins-ExtraBoldItalic.eot");
    src: url("#{$font-path}/Poppins-ExtraBoldItalic.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/Poppins-ExtraBoldItalic.woff2") format("woff2"),
        url("#{$font-path}/Poppins-ExtraBoldItalic.woff") format("woff"),
        url("#{$font-path}/Poppins-ExtraBoldItalic.ttf") format("truetype");
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: "Poppins";
    src: url("#{$font-path}/Poppins-Regular.eot");
    src: url("#{$font-path}/Poppins-Regular.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/Poppins-Regular.woff2") format("woff2"),
        url("#{$font-path}/Poppins-Regular.woff") format("woff"),
        url("#{$font-path}/Poppins-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url("#{$font-path}/Poppins-BoldItalic.eot");
    src: url("#{$font-path}/Poppins-BoldItalic.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/Poppins-BoldItalic.woff2") format("woff2"),
        url("#{$font-path}/Poppins-BoldItalic.woff") format("woff"),
        url("#{$font-path}/Poppins-BoldItalic.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "Poppins";
    src: url("#{$font-path}/Poppins-ExtraBold.eot");
    src: url("#{$font-path}/Poppins-ExtraBold.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/Poppins-ExtraBold.woff2") format("woff2"),
        url("#{$font-path}/Poppins-ExtraBold.woff") format("woff"),
        url("#{$font-path}/Poppins-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url("#{$font-path}/Poppins-SemiBold.eot");
    src: url("#{$font-path}/Poppins-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/Poppins-SemiBold.woff2") format("woff2"),
        url("#{$font-path}/Poppins-SemiBold.woff") format("woff"),
        url("#{$font-path}/Poppins-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url("#{$font-path}/Poppins-MediumItalic.eot");
    src: url("#{$font-path}/Poppins-MediumItalic.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/Poppins-MediumItalic.woff2") format("woff2"),
        url("#{$font-path}/Poppins-MediumItalic.woff") format("woff"),
        url("#{$font-path}/Poppins-MediumItalic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
}

// FONTS GEORGIA

@font-face {
    font-family: "Georgia";
    src: url("#{$font-path}/Georgia.eot");
    src: url("#{$font-path}/Georgia.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/Georgia.woff2") format("woff2"),
        url("#{$font-path}/Georgia.woff") format("woff"),
        url("#{$font-path}/Georgia.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Georgia";
    src: url("#{$font-path}/Georgia-BoldItalic.eot");
    src: url("#{$font-path}/Georgia-BoldItalic.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/Georgia-BoldItalic.woff2") format("woff2"),
        url("#{$font-path}/Georgia-BoldItalic.woff") format("woff"),
        url("#{$font-path}/Georgia-BoldItalic.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "Georgia";
    src: url("#{$font-path}/Georgia-BoldItalic_1.eot");
    src: url("#{$font-path}/Georgia-BoldItalic_1.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/Georgia-BoldItalic_1.woff2") format("woff2"),
        url("#{$font-path}/Georgia-BoldItalic_1.woff") format("woff"),
        url("#{$font-path}/Georgia-BoldItalic_1.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "Georgia";
    src: url("#{$font-path}/Georgia-Italic.eot");
    src: url("#{$font-path}/Georgia-Italic.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/Georgia-Italic.woff2") format("woff2"),
        url("#{$font-path}/Georgia-Italic.woff") format("woff"),
        url("#{$font-path}/Georgia-Italic.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "Georgia";
    src: url("#{$font-path}/Georgia-Bold.eot");
    src: url("#{$font-path}/Georgia-Bold.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/Georgia-Bold.woff2") format("woff2"),
        url("#{$font-path}/Georgia-Bold.woff") format("woff"),
        url("#{$font-path}/Georgia-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Georgia";
    src: url("#{$font-path}/Georgia-BoldItalic_2.eot");
    src: url("#{$font-path}/Georgia-BoldItalic_2.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/Georgia-BoldItalic_2.woff2") format("woff2"),
        url("#{$font-path}/Georgia-BoldItalic_2.woff") format("woff"),
        url("#{$font-path}/Georgia-BoldItalic_2.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "Georgia";
    src: url("#{$font-path}/Georgia-Italic_1.eot");
    src: url("#{$font-path}/Georgia-Italic_1.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/Georgia-Italic_1.woff2") format("woff2"),
        url("#{$font-path}/Georgia-Italic_1.woff") format("woff"),
        url("#{$font-path}/Georgia-Italic_1.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "Georgia";
    src: url("#{$font-path}/Georgia-Bold_1.eot");
    src: url("#{$font-path}/Georgia-Bold_1.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/Georgia-Bold_1.woff2") format("woff2"),
        url("#{$font-path}/Georgia-Bold_1.woff") format("woff"),
        url("#{$font-path}/Georgia-Bold_1.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Seriffic Grunge";
    src: url("SerifficGrunge-Bold.eot");
    src: url("SerifficGrunge-Bold.eot?#iefix") format("embedded-opentype"),
        url("SerifficGrunge-Bold.woff2") format("woff2"),
        url("SerifficGrunge-Bold.woff") format("woff"),
        url("SerifficGrunge-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Georgia";
    src: url("#{$font-path}/Georgia-Italic_2.eot");
    src: url("#{$font-path}/Georgia-Italic_2.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/Georgia-Italic_2.woff2") format("woff2"),
        url("#{$font-path}/Georgia-Italic_2.woff") format("woff"),
        url("#{$font-path}/Georgia-Italic_2.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "Georgia";
    src: url("#{$font-path}/Georgia_1.eot");
    src: url("#{$font-path}/Georgia_1.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/Georgia_1.woff2") format("woff2"),
        url("#{$font-path}/Georgia_1.woff") format("woff"),
        url("#{$font-path}/Georgia_1.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

// For testing
@font-face {
    font-family: "Bebas Neue";
    src: url("#{$font-path}-example/BebasNeue-Regular.eot");
    src: url("#{$font-path}-example/BebasNeue-Regular.eot#iefix") format("embedded-opentype"),
        url("#{$font-path}-example/BebasNeue-Regular.woff2") format("woff2"),
        url("#{$font-path}-example/BebasNeue-Regular.woff") format("woff"),
        url("#{$font-path}-example/BebasNeue-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}


html {
    font-family: "Poppins"; 
    font-weight: normal;
    font-style: normal;
    line-height: 1.2;
    -webkit-text-size-adjust: 100%; 
    -ms-text-size-adjust: 100%; 
    -ms-overflow-style: scrollbar;
    // scroll-behavior: smooth;
}

body {
    font-family: "Poppins";
    font-weight: normal;
    font-style: normal;
    line-height: 1.2;
    color: #000000;
}

::-moz-selection { 
    color: $primary-color;
    background: transparent;
}

::selection {
    color: $primary-color;
    background: transparent;
}