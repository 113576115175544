// *********************************************************
// Footer
// *********************************************************

.footer {

    height: auto !important;
    min-height: auto;
    background: #FFFFFF;

    [class~="col"], [class*="col-"], [class*="col_"] {
        margin: rem(150) 0;

        @media only screen and (max-width: $medium-device) {
            margin: rem(50) 0;

            &:nth-child(1) {
                order: 3;
            }
        }
    }

    &__wrapper {
        margin: auto;
        height: 100%;
        width: 100%;
        position: relative;

        [class~="grid"], [class*="grid-"], [class*="grid_"] {
            padding: 0 rem(200);

            @media only screen and (max-width: $large-device) {
                padding: 0 2rem;
            }
        }
    }

    // Logo wrapper

    &__logo {
        
        @media only screen and (max-width: $large-device) {
            text-align: center;
        }
    }

    &__logo-heading {
        font-size: rem(32);
        font-size: 2vw;
        font-weight: bold;
        margin-bottom: rem(12);
        margin-bottom: 0.7vw;

        @media only screen and (max-width: $large-device) {
            font-size: rem(28);
            margin-bottom: rem(8);
        }
    }

    &__logo-link {
        font-size: rem(16);
        font-size: 1vw;
        font-weight: 500;

        @media only screen and (min-width: $small-device), (any-hover: hover) {

            &:hover {
                color: $primary-color;
                transition: all 300ms ease;
            }
        }

        @media only screen and (max-width: $large-device) {
            font-size: rem(12);
        }
    }

    // Footer box

    &__box {
        display: flex;
        align-items: center;

        @media only screen and (min-width: $small-device), (any-hover: hover) {

            &:hover .footer__contact-images {
                filter: grayscale(100%);
                transition: all 300ms ease;
            }

            &:hover .footer__contact-heading {
                color: #000000;
                transition: all 300ms ease;
            }

            &:hover .footer__icon {
                opacity: 0.5;
                transition: all 300ms ease;
            }
        }

        @media only screen and (max-width: $large-device) {
            justify-content: center;
        }
    }

    &__icon {
        margin-right: rem(40);
        margin-right: 2.5vw;

        @media only screen and (max-width: $large-device) {
            margin-right: rem(24);
        }
    }

    &__icon-svg {
        width: 7vw; 
        height: auto;

        @media only screen and (max-width: $large-device) {
            width: rem(100);
        }
    }

    // Footer info wrapper

    &__info {
        margin-bottom: rem(12);
        margin-bottom: 0.7vw;

        @media only screen and (max-width: $large-device) {
            margin-bottom: rem(8);
        }
    }

    // Footer heading office country

    &__heading {
        font-size: rem(16);
        font-size: 1vw;
        font-weight: bold;
        margin-bottom: rem(12);
        margin-bottom: 0.7vw;

        @media only screen and (max-width: $large-device) {
            font-size: rem(14);
            margin-bottom: rem(8);
        }
    }

    // Footer link email addres

    &__link,
    &__link:link,
    &__link:visited {
        font-size: rem(16);
        font-size: 1vw;
        color: $primary-color;

        @media only screen and (max-width: $large-device) {
            font-size: rem(14);
        }
    }

    // Footer contact wrapper

    &__contact {
        display: flex;
        align-items: center;
    }

    &__contact-images {
        width: rem(60);
        width: 3vw;
        height: rem(60);
        height: 3vw;
        margin-right: rem(15);
        margin-right: 0.85vw;

        @media only screen and (max-width: $large-device) {
            width: rem(60);
            height: rem(60);
            margin-right: rem(15);
        }

        @media only screen and (max-width: $large-device) {
            width: rem(30);
            height: rem(30);
            margin-right: rem(7);
        }
    }

    &__contact-heading {
        font-size: rem(12);
        font-size: 0.8vw;
        color: #7D828B;
        margin-bottom: rem(8);
        margin-bottom: 0.4vw;

        @media only screen and (max-width: $large-device) {
            font-size: rem(12);
            margin-bottom: rem(4);
        }
    }

    &__contact-link {
        font-size: rem(16);
        font-size: 1vw;
        font-weight: bold;

        @media only screen and (max-width: $large-device) {
            font-size: rem(14);
        }
    }
}