// *********************************************************
// Testimonial
// *********************************************************

.testimonial {
    text-align: center;
    padding: 0 2rem;

    &__photo-wrapper {
        width: rem(60);
        border-radius: 50%;
        margin: 0 auto 2rem auto;
    }

    &__text {
        font: {
            family: "Georgia";
            weight: normal;
            size: 1.8rem;
        }
        margin-bottom: 2rem;
    }

    &__quote {
        display: inline-block;

        &::before {
            content: '"';
        }

        &::after {
            content: '"';
        }
    }

    &__author {
        font: {
            size: 0.8rem;
            weight: 600;
        }
        text-transform: uppercase;
        margin-bottom: 0.5rem;
    }

    &__job {
        font-size: 0.75rem;
        color: color($testimonial, job, color);
        text-transform: uppercase;
    }
}