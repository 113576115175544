
  
/* Perspective */
  
.perspective {
    position: relative;
    width: 100%;
    perspective: 800px;
    perspective-origin: 50% 100%;
}
  
.animation__item {
    position: relative;
    display: block;
    flex: none;
    max-width: 250px;
    color: $primary-color;
    margin: 1.5em 2vw;
    cursor: default;
    transform-style: preserve-3d;

    &:focus {
        outline: none;
        color: $primary-color;
    }

    @media only screen and (min-width: $medium-device) {

        &:hover {
            color: $primary-color;
        }

        &:hover .animation__item-title{
            color: $primary-color;
        }
    }

    @media only screen and (max-width: $medium-device) {
        margin: 0;

        &:not(:last-child) {
            margin-bottom: rem(64);
        }

        &,
        &:focus {
            color: transparent;
        }
    }

    @media only screen and (max-width: $extra-small-device) {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: rem(40);
    }
}
  
.stack {
    position: relative;
    width: 100%;
    height: rem(200);
    height: 12vw;
    transform-style: preserve-3d;

    @media only screen and (max-width: $medium-device) {
        height: rem(150);
    }
}
  
.stack__deco {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: currentColor;
    transform-origin: 50% 100%;

    &:first-child {
        opacity: 0.2;
    }

    &:nth-child(2) {
        opacity: 0.4;
    }

    &:nth-child(3) {
        opacity: 0.6;
    }

    &:nth-child(4) {
        opacity: 0.8;
    }
}
  
.stack__figure {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
    cursor: pointer;
    transform-origin: 50% 100%;
}
  
.stack__img {
    max-width: none;
    height: 140%;
    height: 19vw;
    width: auto;
    object-fit: cover;

    @media only screen and (min-width: 157em) {
        height: 22vw;
    }

    @media only screen and (max-width: $medium-device) {
        height: auto;
        width: 100%;
    }
}
  
.animation__item-caption {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    transform-style: preserve-3d;
}
  
// .animation__animation {
//     width: 100%;
//     margin-top: 2rem;
//     margin-bottom: 1rem;
//     text-align: left;
// }

.animation__item-title {
    font-size: rem(20);
    font-size: 1.2vw;
    font-weight: bold;
    width: 100%;
    margin-top: 2rem;
    margin-top: 2vw;
    margin-bottom: 1rem;
    margin-bottom: 1vw;
    text-align: left;
    color: #FFFFFF;

    @media only screen and (max-width: $medium-device) {
        font-size: rem(20);
        margin-top: 2rem;
        margin-bottom: 1rem;
    }
}

.animation__text {
    font-size: rem(14);
    font-size: 0.9vw;
    color: #DBEAFF;
    line-height: 1.6;

    @media only screen and (max-width: $medium-device) {
        font-size: rem(14);
    }
}
  
/* Individual effects */
  
/* Vega */
  
.animation--effect-vega .column {
    opacity: 1;
}
  